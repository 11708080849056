import {Injectable} from '@angular/core';
import {FormConfigService} from '@csm/shared';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import * as _ from "lodash";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {FormConfig} from '@symplicity/syng';

@Injectable({ providedIn: 'root' })
export class EventRegistrationFormConfigService extends FormConfigService {

    private weAreLookingForFields: Array<string> = ['industry', 'employer_type', 'degree_level', 'position_types', 'majors', 'work_authorization'];
    private representativeInfoFields: Array<string> = ['rep_info'];
    private groupChatInfoFields: Array<string> = ['group_chat_info', 'group_chat_schedule'];

    constructor (
        protected http:HttpClient,
        private translate: TranslateService
    ) {
        super(http);
    }

    public getFormConfigs(eventId: string, eventsService: any): Observable<any> {
        if (!this.formConfigs.hasOwnProperty(eventId)) {
            const configSubject = new Subject<any>();
            eventsService.getProfileFieldsFormStructure(eventId).subscribe((formStructure) => {
                this.formConfigs[eventId] = JSON.stringify(formStructure);
                configSubject.next(JSON.parse(this.formConfigs[eventId]));
            });

            return configSubject;
        } else {
            return new BehaviorSubject(JSON.parse(this.formConfigs[eventId]));
        }
    }

    public setupFormConfigs(formStructure: object, employerData: object) {
        let weAreLookingForFieldStructure: Array<object> = [];
        let representativeInfoFieldStructure: Array<object> = [];
        let groupChatInfoFieldStructure: Array<object> = [];

        let i = formStructure['fields'].length;
        while (i--) {
            let fieldGroup = formStructure['fields'][i]['group_fields'];
            let j = fieldGroup.length;

            while (j--) {
                let field = fieldGroup[j];

                let value = employerData[field.field_key];
                if (!_.isEmpty(employerData) && (!value || (Array.isArray(value) && !value.length))) {
                    formStructure['fields'][i]['group_fields'].splice(j, 1);
                } else {
                    if ((this.weAreLookingForFields.indexOf(field.field_key)) !== -1) {
                        weAreLookingForFieldStructure.push(field);
                        formStructure['fields'][i]['group_fields'].splice(j, 1);
                    } else if ((this.representativeInfoFields.indexOf(field.field_key)) !== -1) {
                        representativeInfoFieldStructure.push(field);
                        formStructure['fields'][i]['group_fields'].splice(j, 1);
                    } else if ((['address', 'rep'].indexOf(field.field_type)) !== -1) {
                        formStructure['fields'][i]['group_fields'][j]['field_type'] = 'picklist';
                        formStructure['fields'][i]['group_fields'][j]['field_type_options']['multiple'] = true;
                    } else if ((this.groupChatInfoFields.indexOf(field.field_key)) !== -1) {
                        groupChatInfoFieldStructure.push(field);
                        formStructure['fields'][i]['group_fields'].splice(j, 1);
                    }
                }
            }

            if (formStructure['fields'][i]['group_fields'].length === 0) {
                formStructure['fields'].splice(i, 1);
            }
        }

        let weAreLookingForFormConfig = null;
        if (!_.isEmpty(weAreLookingForFieldStructure)) {
            let weAreLookingForFormStructure = this.getWeAreLookingForFormStructure('we_are_looking_for', 'career_fair students.We Are Looking For', weAreLookingForFieldStructure, true);
            weAreLookingForFormConfig = this.setFormConfig(weAreLookingForFormStructure, '', '', '', '');
            weAreLookingForFormConfig = this.setFormReadOnly(weAreLookingForFormConfig);
        }

        let representativeInfoFormConfig = null;
        if (!_.isEmpty(representativeInfoFieldStructure)) {
            let representativeInfoFormStructure = this.getWeAreLookingForFormStructure('representative_info', 'career_fair students.Representatives', representativeInfoFieldStructure, true);
            representativeInfoFormConfig = this.setFormConfig(representativeInfoFormStructure, '', '', '', '');
            representativeInfoFormConfig = this.setFormReadOnly(representativeInfoFormConfig);
        }

        let groupChatInfoFormConfig = null;
        if (!_.isEmpty(groupChatInfoFieldStructure)) {
            let groupChatInfoFormStructure = this.getWeAreLookingForFormStructure('group_chat_info', 'career_fair students.Group Chat', groupChatInfoFieldStructure);
            groupChatInfoFormConfig = this.setFormConfig(groupChatInfoFormStructure, '', '', '', '');
            groupChatInfoFormConfig = this.setFormReadOnly(groupChatInfoFormConfig);
        }

        let otherFieldsConfig = null;
        if (!_.isEmpty(formStructure['fields'])) {
            otherFieldsConfig = this.setFormConfig(formStructure, '', '', '', '');
            otherFieldsConfig = this.setFormReadOnly(otherFieldsConfig);
        }

        return {
            weAreLookingForFormConfig: weAreLookingForFormConfig,
            representativeInfoFormConfig: representativeInfoFormConfig,
            groupChatInfoFormConfig: groupChatInfoFormConfig,
            otherFieldsConfig: otherFieldsConfig
        };
    }

    private getWeAreLookingForFormStructure(id: string, title: string, fields: Array<object>, reverse: boolean = false): Object {
        return {
            fields: [
                {
                    group_fields: reverse ? fields.reverse(): fields,
                    group_id: id,
                    group_title: this.translate.instant(title)
                }
            ]
        };
    }

    private setFormReadOnly(formConfig: FormConfig): FormConfig {
        formConfig.readOnly = true;
        formConfig.controlGroups.forEach((group) => {
            _.forEach(group.controls, (field) => {
                switch (field.type) {
                    case 'relation':
                        field.readOnly = true;
                        break;
                    default:
                        field.type = 'static';
                }
                field.readOnlyForm = true;
            });
        });

        return formConfig;
    }
}
