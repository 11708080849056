<div aria-live="polite" class="d-flex field-widget-tinymce flex-grow-1">
    <div keyboardTrap class="d-flex flex-column flex-grow-1" *ngIf="announcementData$ | async as announcementData; else loading">
        @if (!customLayout) {
        <div class="modal-header">
            <h1 class="h3-text">
                {{ translationDomain + '.Announcement' | translate }}
            </h1>
            <button
            class="modal-close"
            [attr.aria-label] = "'misc.Close button icon' | translate"
            (click)="bsModalRef.hide()"
            ></button>
        </div>
        <div class="modal-body" tabindex="0">
            <h2 class="h1-text no-margin">{{announcementData.title}}</h2>
            <div class="text-gray space-bottom-lg">{{announcementData.postedAt}}</div>

            <div
                *ngIf="announcementData.banner_image.background_image"
                [ngStyle]="announcementData.inlineStyles"
                class="modal-banner-img background-img-fit-width space-bottom-lg"
                ></div>

            <div [innerHTML]="announcementData.body | safeHtml"></div>
        </div>

        <div class="modal-footer">
            <button
                class="btn"
                (click)="bsModalRef.hide()"
            >
            {{'misc.Close' | translate}}
            </button>
        </div>
        } @else {
            <ng-container *ngTemplateOutlet="customLayout; context: { $implicit: announcementData, close: bsModalRef.hide }"></ng-container>
        }
    </div>
</div>

<!------------------------------------------------------------>
<ng-template #loading>
    <div class="sr-only">{{'misc.Loading | translate'}}</div>
    <div class="list_rows p-4 w-100">
        <div>
            <div class="skeleton-bar col-6" style="height: 36px;"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-3"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar modal-banner-img"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-12"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-12"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-11"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-10"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-9"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-8"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-6"><span class="skeleton-animation"></span></div>
            <div class="skeleton-bar col-4"><span class="skeleton-animation"></span></div>

        </div>
    </div>
</ng-template>
