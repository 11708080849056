import { ViewChild, Component, Input, TemplateRef, ViewContainerRef, Inject, Optional, HostBinding, ViewChildren, QueryList } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { JanusFormComponent } from '@symplicity/syng';

@Component({
    selector: 'ngx-modal',
    templateUrl: './ngx-modal.component.html',
    host: {
        class: 'd-flex flex-column overflow-hidden h-100 h-sm-auto'
    }
})

export class NgxModalComponent {

    @ViewChild('templateRef', {read: ViewContainerRef}) vc: ViewContainerRef;
    @ViewChild('formButtons', {read: ViewContainerRef}) formButtons: ViewContainerRef;

    @Input() alertHeaderTitle;
	@Input() alertMessage;
	@Input() alertButtonName;
    @Input() alertButtonClass;
    @Input() alertCancelButtonName;
    @Input() alertAllowClose = true;
    @Input() isAlertMessageTranslated = false;
    @Input() hasFooter = true;
    @Input() whiteHeader = false;
    @Input() whiteFooter = false;
    @Input() hostClass: string = '';
    @Input() activeBodyTabIndex: boolean = false;
	@Input()
    set formRef(value: any) {
        this._formRef = value;
        if (this._formRef) {
            this._formRef.renderButtonsOutsideForm(this.formButtons, false, 'w-100');
        }
    }
    @HostBinding('class')
    get classes(): string {
        return this.hostClass;
    }

    private _formRef: any;
    private htmlContent;
    public useTemplateRef: boolean = false;
    public alertTitle: '';
    public onClose: Subject<boolean>;

    constructor(
        public bsModalRef: BsModalRef,
		private sanitizer: DomSanitizer,
        private ViewContainerRef: ViewContainerRef,
        @Optional() @Inject('ENABLE_JANUS') public janus: number,
    ) {
    }

    ngOnInit(): void {
        if (this.isAlertMessageTranslated) {
            this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.alertMessage);
        }
    }

    public onConfirm(): void {
        this.onClose?.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose?.next(false);
        this.bsModalRef.hide();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.alertMessage instanceof TemplateRef) {
                this.useTemplateRef = true;
                const view = this.ViewContainerRef.createEmbeddedView(this.alertMessage);
                this.vc.insert(view);
            }
        });
    }
}
