<ng-container *ngIf="bannerData else bannerSkeleton;">
    <div class="hero-announcement-carousel-wrapper" #bannerFlickity *ngIf="bannerData.models.length">

        <button *ngIf="pauseButton" (click)="onPause()" class="flickity-button flickity-pause-button" [ngClass]="bannerFlickityPaused ? 'icn-play_alt' : 'icn-pause'" type="button" [attr.aria-label]="bannerFlickityPaused ? (translationDomain + '.Unpause' | translate) : (translationDomain + '.Pause' | translate)">
            <ng-container *ngIf="customButtons && customButtons.pause && !bannerFlickityPaused"><ng-container *ngTemplateOutlet="customButtons.pause"></ng-container></ng-container>
            <ng-container *ngIf="customButtons && customButtons.play && bannerFlickityPaused"><ng-container *ngTemplateOutlet="customButtons.play"></ng-container></ng-container>
        </button>

        <div *ngIf="customButtons && customButtons.chevron" class="visually-hidden" #customChevron><ng-container *ngTemplateOutlet="customButtons.chevron"></ng-container></div>
        
        <div flickity
            [flickityConfig]="bannerFlickityOptions"
            (ready)="bannerReady($event)"
            (staticClick)="onStaticClick()"
            class="hero-announcement-carousel"
            [intersectionObserver]="intersectionCallback"
            >
            <ng-container *ngFor="let model of bannerData.models; index as i">
                <a
                    *ngIf="model.custom_link_override; else linkModalTemplate;"
                    [attr.href]="model.custom_link_override"
                    [attr.target]="model.target" [attr.rel]="model.target === '_blank' ? 'noopener noreferrer' : ''"
                    [ngClass]="['hero-announcement-carousel-slide', model.theme]"
                    [class.use_bg]="model.use_background_overlay"
                    [ngStyle]="model.extra_styles"
                    tabindex="-1"
                    >
                    <h3>{{model.headline}}</h3>
                    <div class="hero-announcement-card-subtitle">
                        <span class="hero-announcement-card-subtitle-text">{{model.subheading}}</span>
                        <button class="hero-announcement-call-to-action">{{model.call_to_action}}</button>
                    </div>
                    <div class="hero-announcement-card-colors"></div>
                </a>
                <ng-template #linkModalTemplate>
                    <div
                        [ngClass]="['hero-announcement-carousel-slide', model.theme]"
                        [class.use_bg]="model.use_background_overlay"
                        [ngStyle]="model.extra_styles"
                        tabindex="-1"
                        >
                        <h3>{{model.headline}}</h3>
                        <div class="hero-announcement-card-subtitle">
                            <span class="hero-announcement-card-subtitle-text">{{model.subheading}}</span>
                            <button class="hero-announcement-call-to-action" (keydown.enter)="onStaticClick()">{{model.call_to_action}}</button>
                        </div>
                        <div class="hero-announcement-card-colors"></div>
                    </div>
                </ng-template>
            </ng-container>
        </div>
        <span #liveRegion aria-live="polite" aria-atomic="true" class="sr-only"></span>
    </div>
</ng-container>

<ng-template #bannerSkeleton>
    <div *ngIf="loadingSkeleton" class="skeleton-fill hero-announcement-carousel-wrapper">
        <span class="skeleton-animation"></span>
    </div>
</ng-template>