import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { JanusWindowSizeService, ValkyrieBackendService } from "@symplicity/syng";
import { fromEvent, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

declare var window: Window;

@Component({
    selector: 'hero-banner',
    templateUrl: 'hero-banner.component.html'
})
export class HeroBannerComponent {
    @ViewChild("bannerFlickity", {static: false}) bannerFlickityEl: ElementRef<HTMLDivElement>;
    @ViewChild("liveRegion", {static: false}) liveRegionEl: ElementRef<HTMLSpanElement>;
    @ViewChild("customChevron", { static: false }) customChevron: ElementRef<HTMLSpanElement>;

    bannerFlickity: any;
    bannerSubscription: Subscription;
    bannerFocusSubscription: Subscription;
    bannerBlurSubscription: Subscription;
    bannerData: any;
    screenSize: string = '';

    @Input() translationDomain = 'homepage hero_banner';
    @Input() customButtons = [];
    @Input() pauseButton = false;
    @Input() forceXsFocusBehavior = false;
    @Input() disableFirstPrevLastNext = false;

    @Output() onOpenModal = new EventEmitter<any>();
    @Output() emptyModels = new EventEmitter<void>();

   bannerFlickityOptions = {
        "wrapAround": true,
        "autoPlay": 5000,
        "rightToLeft": window.document.dir === 'rtl',
        "accessibility": false,
        "imagesLoaded": true,
        "prevNextButtons": true,
        "pageDots": true
    }
    bannerFlickityPaused = false;

    KEYS = {
        ENTER: 13,
        SPACE: 32,
        LEFT:  37,
        UP:    38,
        RIGHT: 39,
        DOWN:  40,
        TAB:   9
    };

    constructor (
        private backendService: ValkyrieBackendService,
        private translate: TranslateService,
        private zone: NgZone,
        private windowSize: JanusWindowSizeService
    ) {
        this.intersectionCallback = this.intersectionCallback.bind(this);
        this.windowSize.breakpoints$().pipe(takeUntilDestroyed()).subscribe((size) => {
            this.screenSize = size;
        })
    }

    ngOnInit() {
        this.backendService.getAll('announcements', 'v2',{ listdef: 'HeroBannerAnnouncementList', perPage:'5' })
            .subscribe( res => {
                if (!res.models.length) {
                    this.bannerData = res;
                    this.emptyModels.emit();
                    return;
                }
                if (res.models.length === 1) {
                    this.bannerFlickityOptions.pageDots = false;
                    this.bannerFlickityOptions.prevNextButtons = false;
                    this.pauseButton = false;
                }

                res.models = res.models.map(model => {
                    model.use_background_overlay = false;

                    if (model.background_image) {
                        const background_position = model.background_image_alignment ? model.background_image_alignment.replace("_", " ") : "center center";
                        model.extra_styles = {"background-image": "url(" + model.background_image + ")", "background-position": background_position};
                        model.use_background_overlay = true;
                    }
                    if (model.custom_link_override) {
                        model.target = "_self";
                        const url = new URL(model.custom_link_override);
                        if (url.protocol === "http:") {
                            model.custom_link_override = model.custom_link_override.replace("http:", "https:")
                        }
                        if (url.host !== window.location.host) {
                            model.target = "_blank"
                        }
                    }
                    return model;
                })
                this.bannerData = res;
            });
        }

    ngOnDestroy() {
        if(this.bannerSubscription) { this.bannerSubscription.unsubscribe(); }
        if(this.bannerBlurSubscription) { this.bannerBlurSubscription.unsubscribe(); }
        if(this.bannerFocusSubscription) { this.bannerFocusSubscription.unsubscribe(); }
        this.onOpenModal.unsubscribe();
    }

    onStaticClick() {
        this.openModal(this.bannerFlickity.selectedIndex)
    }

    bannerReady(flickity) {
        this.bannerFlickity = flickity;
        this.bannerSubscription = this.flickityKeyboardListener(this.bannerFlickityEl.nativeElement, this.bannerFlickity);
        this.bannerBlurSubscription = fromEvent(this.bannerFlickityEl.nativeElement, 'focusout').subscribe(() => {
            !this.bannerFlickityPaused && this.bannerFlickity.playPlayer();
        })
        this.bannerFocusSubscription = fromEvent(this.bannerFlickityEl.nativeElement, 'focusin').subscribe(() => {
            this.bannerFlickity.stopPlayer();
        })

        if (this.bannerFlickity.slides.length > 1) {
            if (this.disableFirstPrevLastNext) {
                this.setDisabledNextPrev();
            }
            this.setupTabNavigation();
        }
    }

    intersectionCallback(entries: IntersectionObserverEntry[]) {
        if (!this.bannerFlickity) { return }
        if (!entries[0].isIntersecting) {
            this.bannerFlickity.stopPlayer();
        } else {
            if (!document.activeElement.closest('.hero-announcement-carousel-wrapper')) {
                this.bannerFlickity.playPlayer();
            }
        }
    }

    openModal(cellIndex: number) {
        if (this.bannerData.models[cellIndex].custom_link_override) return; // If item has custom_link_override, don't show modal.
        this.onOpenModal.emit(this.bannerData.models[cellIndex].announcement_id);
        this.bannerFlickity.stopPlayer();
    }

    onPause() {
        if (this.bannerFlickityPaused) {
            this.bannerFlickity.playPlayer();
        } else {
            this.bannerFlickity.stopPlayer();
        }

        this.bannerFlickityPaused = !this.bannerFlickityPaused
    }

    flickityKeyboardListener(flickityElement, flickity) {
        return fromEvent<KeyboardEvent>(flickityElement, 'keyup').pipe(
            filter(e => (!!Object.keys(this.KEYS).find(key => this.KEYS[key] == e.keyCode)) && (e.target as HTMLElement).tagName !== "BUTTON")
            ).subscribe(e => {
                switch (e.keyCode) {
                    case this.KEYS.LEFT:
                    case this.KEYS.UP: {
                        this.handleAxFlow(flickity, false);
                        break;
                    }
                    case this.KEYS.RIGHT:
                    case this.KEYS.DOWN: {
                        this.handleAxFlow(flickity, true);
                        break;
                    }
                    case this.KEYS.SPACE:
                    case this.KEYS.ENTER: {
                        this.zone.run(() => {
                            this.openModal(flickity.selectedIndex)
                        });
                        break;
                    }
                }
            }
            )
    }

    handleAxFlow(flickity: any, next: boolean) {
        if (next) {
            flickity.next(true, false); // 'next' and 'previous' params:  isWrapped: wrap around the elements | isInstant: without animation.
        } else {
            flickity.previous(true, false);
        }

        flickity.selectedElement.focus();
        this.liveRegionEl.nativeElement.textContent = this.translate.instant(
            'homepage hero_banner.announcement x of y', {position: flickity.selectedIndex + 1, total: flickity.slides.length}
        );
    }

    setDisabledNextPrev() {
        let prevButton: Element = document.querySelector('.flickity-prev-next-button.previous') as Element;
        prevButton.setAttribute('disabled', 'true');
        this.bannerFlickity.on('change', (slide) => {
            let nextButton: Element = document.querySelector('.flickity-prev-next-button.next') as Element;
            let prevButton: Element = document.querySelector('.flickity-prev-next-button.previous') as Element;

            if (slide === (this.bannerFlickity.slides.length -1)) {
                nextButton.setAttribute('disabled', 'true');
            } else {
                nextButton.removeAttribute('disabled');
            }
            if (slide === 0) {
                prevButton.setAttribute('disabled', 'true');
            } else {
                prevButton.removeAttribute('disabled');
            }
        })
    }

    setupTabNavigation() {
        let prevNextButtons = document.querySelectorAll('.flickity-prev-next-button');

        if (prevNextButtons && this.customButtons && this.customButtons['chevron']) {
            let customChevron = this.customChevron.nativeElement.innerHTML;
            prevNextButtons.forEach((ea) => { ea.innerHTML = customChevron; })
            this.customChevron.nativeElement.remove();
        }

        let ctaButtons: NodeListOf<Element> = document.querySelectorAll('.hero-announcement-call-to-action');
        let prevButton: HTMLElement;
        let nextButton: HTMLElement;
        let pauseButton: HTMLElement = document.querySelector('.flickity-pause-button');

        if (prevNextButtons) {
            let prevButtonEl: Element;
            let nextButtonEl: Element;

            prevNextButtons.forEach((ea) => {
                if (ea.classList.contains('previous')) {
                    prevButton = ea as HTMLElement;
                    prevButtonEl = ea;
                }

                if (ea.classList.contains('next')) {
                    nextButton = ea as HTMLElement;
                    nextButtonEl = ea;
                }                    
            })

            prevButtonEl.setAttribute('aria-label', this.translate.instant(this.translationDomain + '.Previous'));
            prevButtonEl.addEventListener('keydown', (keydown: KeyboardEvent) => {
                if (keydown.keyCode === this.KEYS.TAB) {
                    keydown.preventDefault();
                    let ctaButton: HTMLElement = this.bannerFlickity.selectedElement.querySelector(".hero-announcement-call-to-action");
                    
                    if (!keydown.shiftKey) {
                        if (this.focusXsMode()) {
                            if (!nextButton.getAttribute('disabled')) {
                                nextButton.focus();
                            } else {
                                ctaButton.focus();
                            }
                        } else {
                            if (ctaButton) {
                                ctaButton.focus();
                            }
                        }
                    } else {
                        if (this.focusXsMode()) {
                            if (ctaButton) {
                                ctaButton.focus();
                            }
                        } else {
                            pauseButton.focus();
                        }
                    }
                }
            });
            
            nextButtonEl.setAttribute('aria-label', this.translate.instant(this.translationDomain + '.Next'));
            nextButtonEl.addEventListener('keydown', (keydown: KeyboardEvent) => {
                if (keydown.keyCode === this.KEYS.TAB) {
                    if (!keydown.shiftKey) {
                        if (this.bannerFlickity.selectedIndex !== (this.bannerFlickity.slides.length - 1)) {
                            keydown.preventDefault();
                            this.bannerFlickity.select((Number(this.bannerFlickity.selectedIndex) + 1), false, true);
                            pauseButton.focus();
                        }
                    } else {
                        keydown.preventDefault();
                        let ctaButton: HTMLElement = this.bannerFlickity.selectedElement.querySelector(".hero-announcement-call-to-action");
                            
                        if (this.focusXsMode()) {
                            if (!prevButton.getAttribute('disabled')) {
                                prevButton.focus();
                            } else {
                                if (ctaButton) {
                                    ctaButton.focus();
                                }
                            }
                        } else {
                            if (ctaButton) {
                                ctaButton.focus();
                            }
                        }
                    }
                }
            });
        }

        if (pauseButton) {
            pauseButton.addEventListener('keydown', (keydown: KeyboardEvent) => {
                if (keydown.keyCode === this.KEYS.TAB) {
                    if (!keydown.shiftKey) {
                        keydown.preventDefault();
                        let ctaButton: HTMLElement = this.bannerFlickity.selectedElement.querySelector(".hero-announcement-call-to-action");

                        if (this.focusXsMode()) {
                            if (ctaButton) {
                                ctaButton.focus();
                            }
                        } else {
                            if (!prevButton.getAttribute('disabled')) {
                                prevButton.focus();
                            } else {
                                if (ctaButton) {
                                    ctaButton.focus();
                                }
                            }
                        }
                    } else {
                        if (this.bannerFlickity.selectedIndex > 0 && !nextButton.getAttribute('disabled')) {
                            keydown.preventDefault();
                            this.bannerFlickity.select((Number(this.bannerFlickity.selectedIndex) - 1), false, true);
                            nextButton.focus();
                        }
                    }
                }
            });
        }

        ctaButtons.forEach((ctaButton: HTMLElement) => {
            if (ctaButton) {
                ctaButton.addEventListener('keydown', (keydown: KeyboardEvent) => {
                    if (keydown.keyCode === this.KEYS.TAB) {
                        if (!keydown.shiftKey) {
                            if (this.focusXsMode()) {
                                if (!prevButton.getAttribute('disabled')) {
                                    keydown.preventDefault();
                                    prevButton.focus();
                                } else {
                                    if (!nextButton.getAttribute('disabled')) {
                                        keydown.preventDefault();
                                        nextButton.focus();
                                    }
                                }
                            } else {
                                if (!nextButton.getAttribute('disabled')) {
                                    keydown.preventDefault();
                                    nextButton.focus();
                                }
                            }
                        } else {
                            keydown.preventDefault();
                            if (this.focusXsMode()) {
                                pauseButton.focus();
                            } else {
                                if (!prevButton.getAttribute('disabled')) {
                                    prevButton.focus();
                                } else {
                                    pauseButton.focus();
                                }
                            }
                        }
                    }                    
                });
            }
        })
    }

    focusXsMode() {
        return this.forceXsFocusBehavior ? true : this.screenSize === 'xs';
    }
}
